<div class="p-24 p-b-0 m-b-12">
  <div class="d-flex align-content-center">
    <mat-form-field appearance="outline" color="primary" class="hide-hint w-100">
      <input matInput placeholder="Search here" [(ngModel)]="searchText" />
    </mat-form-field>
    <button mat-icon-button mat-dialog-close class="d-flex justify-content-center">
      <i-tabler name="x" class="icon-18 d-flex"></i-tabler>
    </button>
  </div>
</div>
<mat-divider></mat-divider>
<mat-dialog-content class="mat-typography search-dialog">
  <h4 class="f-s-18 f-w-500 mat-subtitle-1 m-b-16">Quick Page Links</h4>
  @for(item of navItemsData; track item.route) {
  <a [routerLink]="[item.route]" mat-dialog-close class="p-y-12 text-decoration-none d-block">
    <h5 class="f-s-14 f-w-500 d-block mat-subtitle-1 m-0">
      {{ item.displayName }}
    </h5>
    <span class="f-s-12 mat-body-2">{{ item.route }}</span>
  </a>
  }
</mat-dialog-content>