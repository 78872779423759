import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

export const authGuard: CanActivateFn = (route, state) => {const authService = inject(AuthService);
  const router = inject(Router);
  console.log("authGuard | validateAuth " + authService.validateAuth());
  if (authService.validateAuth() == false) {
      router.navigate(['/authentication/boxed-login']);
      return false;
  }

  return true;
};
